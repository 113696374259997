import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import SmallBox from "components/smallBox";
import BasicTable from "components/basicTable";
import List from "components/list";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "virus"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/pcparts/virus.png"}) { ...eyecatchImg },
    warning: file(relativePath: { eq: "security/warning.PNG"}) { ...normalImg },
    windowsdefender: file(relativePath: { eq: "security/windows-defender.PNG"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`ウイルス対策ソフト（ウイルスバスター）は必要か？`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="ウイルスバスターって必要？" mdxType="Image" />
    <p>{`コンピューターウイルスと聞いても何をするかピンとこないのだが、ウイルス対策ソフトにはお金がかかるし、さらにバックグラウンドでウイルス対策ソフトが起動することによってパソコンが少し遅くなるとも聞く。`}</p>
    <p>{`そもそもあまり感染しないのであればウイルス対策ソフトなど不要ではないか？無料のものがあればそちらでも代替できないか？`}</p>
    <p>{`このように迷っている人は多いと思う。このトピックではウイルスによる脅威や仕組みの説明、ウイルス対策ソフト（ウイルスバスター）の役割、リスクを考慮した導入検討のための考えをまとめていく。`}</p>
    <p>{`なお、ウイルスバスターはセキュリティソフト会社であるトレンドマイクロの商標である、またウイルスのみがセキュリティ上での脅威ではないためセキュリティ対策ソフトとも言われるが、以後ウイルス対策ソフトで統一する。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "ウイルス対策ソフトが提供する基本機能",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%82%BD%E3%83%95%E3%83%88%E3%81%8C%E6%8F%90%E4%BE%9B%E3%81%99%E3%82%8B%E5%9F%BA%E6%9C%AC%E6%A9%9F%E8%83%BD",
        "aria-label": "ウイルス対策ソフトが提供する基本機能 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルス対策ソフトが提供する基本機能`}</h2>
    <p>{`ウイルス対策ソフトは不正送金やパスワードをだまし取ろうとするもの、パソコンを起動できなくするものなど様々なウイルスから個人情報やパソコンを守るために必要なソフトである。`}</p>
    <p>{`ソフトによって様々な機能が提供されるが、共通して提供される基本的な機能は下記の３つである。`}</p>
    <List mdxType="List">
  <li>既知のウイルスを検知して駆除する</li>
  <li>未知のウイルスを検知して駆除する</li>
  <li>危険なサイトに対してアラートを挙げる</li>
    </List>
    <h3 {...{
      "id": "既知のウイルスを検知",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%97%A2%E7%9F%A5%E3%81%AE%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%82%92%E6%A4%9C%E7%9F%A5",
        "aria-label": "既知のウイルスを検知 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`既知のウイルスを検知`}</h3>
    <p>{`まず、既知のウイルスに対しては`}<strong parentName="p">{`パターンファイル`}</strong>{`という手法で対抗する。これはブラックリスト方式の対策で、ファイルの実行時にウイルススキャンを掛けて、既存のウイルスが存在するかパターンマッチングを図る。`}</p>
    <p>{`既知ウイルスをブラックリストに登録していけば良いだけで技術的には大して難しいことはしていないため、ほとんどのウイルス対策ソフトがほぼ100%の検出率を誇っている。`}</p>
    <h3 {...{
      "id": "未知のウイルスを検知",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9C%AA%E7%9F%A5%E3%81%AE%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%82%92%E6%A4%9C%E7%9F%A5",
        "aria-label": "未知のウイルスを検知 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`未知のウイルスを検知`}</h3>
    <p>{`続いて、未知のウイルスに対しては`}<strong parentName="p">{`振る舞い検知`}</strong>{`という手法で対抗する。`}</p>
    <p>{`これは一般的なウイルスの怪しい動きをビッグデータとして収集・解析することでパターン学習を行い、未知のウイルスを発見する手段として使う方法である。`}</p>
    <p>{`データ量が勝負となるところがあるため、業界トップの大手企業ほど優位となる傾向があるだろう。`}</p>
    <h3 {...{
      "id": "危険なサイトに対してアラートを挙げる",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8D%B1%E9%99%BA%E3%81%AA%E3%82%B5%E3%82%A4%E3%83%88%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6%E3%82%A2%E3%83%A9%E3%83%BC%E3%83%88%E3%82%92%E6%8C%99%E3%81%92%E3%82%8B",
        "aria-label": "危険なサイトに対してアラートを挙げる permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`危険なサイトに対してアラートを挙げる`}</h3>
    <Image {...props} name="warning" alt="セキュリティ警告" mdxType="Image" />
    <p>{`危険なサイトとは、本物の銀行サイトにそっくりなホームページで待ち構え、ID/パスワードの入力を促すフィッシングサイト、ブラウザやプラグインの脆弱性をついて、閲覧しただけでウイルスをダウンロード実行してしまうサイトなどのことを言う。`}</p>
    <p>{`これら危険なサイトに対して事前にフィルタを掛けることにより、閲覧する前に警告画面を表示する。`}</p>
    <SmallBox type="word" text="ウイルスはマルウェアの一種" mdxType="SmallBox">
日本ではウイルスで通っているが、正式にはマルウェアという言い方が正しい。マルウェアはMalicious(悪意のある)Softwareから来た造語で、悪質なプログラムの総称を言う。<br />
ウイルスはマルウェアの一種でコンピュータに侵入し、データを破壊するなど悪質な行為を働くもの。他マルウェアにはコンピュータ内の情報を盗み出し、外部に流出させるスパイウェア、コンピュータに侵入して密かに制御権を奪うトロイの木馬など様々な種類がある。ウイルス対策ソフトはウイルスを含め正式にはマルウェアを検出・除去するソフトと言う事ができる。
    </SmallBox>
    <h2 {...{
      "id": "ウイルス対策ソフトが無いと何が起こるのか？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%82%BD%E3%83%95%E3%83%88%E3%81%8C%E7%84%A1%E3%81%84%E3%81%A8%E4%BD%95%E3%81%8C%E8%B5%B7%E3%81%93%E3%82%8B%E3%81%AE%E3%81%8B%EF%BC%9F",
        "aria-label": "ウイルス対策ソフトが無いと何が起こるのか？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルス対策ソフトが無いと何が起こるのか？`}</h2>
    <p>{`ウイルス対策ソフトは何かしら入れておいた方が良いというのは大半の意見かと思われるが、入れていなかった場合には下記のようなリスクが想定される。`}</p>
    <List mdxType="List">
  <li>メールの添付ファイルを開いたところファイルが暗号化され、元に戻すためのお金を要求された</li>
  <li>フリーソフトをインストールしたところ知らぬ間にID/Passwordが盗まれていた</li>
    </List>
    <p>{`１つめは怪しげなメールの添付ファイルを開くとウイルスに感染したパターン。添付ファイルはWordやExcelのマクロ機能を使ったもの、実行ファイル形式(exe)のものなど様々なパターンがあるが、いずれもファイルに添付されたウイルスプログラムが開くと同時に実行される。`}</p>
    <p>{`既知のウイルスがスパムメールとして添付されて大量にバラまかれるケースが多いため、ウイルス対策ソフトを入れていると大抵は開いてしまっても駆除できる問題である。`}</p>
    <p>{`ちなみに開くとデスクトップなどにあるファイルを強制的に暗号化し、それを復号するために金銭を要求する、この例のようなウイルスは`}<strong parentName="p">{`ランサムウェア`}</strong>{`と呼ばれ、世界中で猛威を振るっている。`}</p>
    <p>{`２つめはWindowsのフリーソフトウェアで何か便利そうなアプリを探してダウンロードすることでウイルスに感染するケース。特に海外サイトのフリーソフトは何かしらウイルスが紛れ込んでいる事が多い。`}</p>
    <p>{`例はID/Passwordが盗まれるパターンであるが、例えばキーボードの入力をバックグラウンドで取得しサーバーに送るというプログラムを秘密裏に仕込む方法で盗まれる。これは`}<strong parentName="p">{`キーロガー`}</strong>{`と呼ばれているが、特に使用者に警告なく送られるため気付くことが難しい。`}</p>
    <p>{`しかし、このケースもウイルスを検知できる可能性が高いため、ウイルス対策ソフトを入れておけば、フリーソフトであっても比較的安全にインストールすることができる。`}</p>
    <SmallBox type="word" text="そもそもウイルスとは？" mdxType="SmallBox">
中身はただのコンピュータープログラムである。ユーザーにとって悪さをすればそれがウイルスと呼ばれているだけで、得体の知れないものではない。簡単なものなら少しプログラミングをかじった大学生でも簡単に作れてしまう代物である。<br />
例えば、起動するとデスクトップ上にある全てのファイルをゴミ箱に入れるプログラムは初心者でも１日で作れてしまう。あとは電子メールを送りつけクリックしてもらえばウイルス発動である。
    </SmallBox>
    <p>{`次にウイルス対策ソフトにある誤解と、ウイルス対策ソフトでは防ぎ切れないケースを説明しておく。`}</p>
    <h3 {...{
      "id": "ソフトを最新版にアップデートするだけでは防げない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%BD%E3%83%95%E3%83%88%E3%82%92%E6%9C%80%E6%96%B0%E7%89%88%E3%81%AB%E3%82%A2%E3%83%83%E3%83%97%E3%83%87%E3%83%BC%E3%83%88%E3%81%99%E3%82%8B%E3%81%A0%E3%81%91%E3%81%A7%E3%81%AF%E9%98%B2%E3%81%92%E3%81%AA%E3%81%84",
        "aria-label": "ソフトを最新版にアップデートするだけでは防げない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ソフトを最新版にアップデートするだけでは防げない`}</h3>
    <p>{`Windowsのアップデートなど、ソフトウェアを常に最新にしておくことでウイルス感染は防ぐことができるので、ウイルス対策ソフトは不要であると主張する人がいるが、これは誤りである。`}</p>
    <p>{`ソフトウェアの多くには脆弱性というセキュリティ観点での不具合があり、この脆弱性を利用することによりウイルスを侵入させることができる。`}</p>
    <p>{`ソフトウェアメーカーは脆弱性が発見されてから修正プログラムをアップデートとして配布することになるが、修正プログラムが完成するまでには幾らか時間が掛ってしまう。`}</p>
    <p>{`つまり、この`}<strong parentName="p">{`脆弱性が発見されてから修正プログラムの配布まではソフトウェアは無防備なまま放置されることになり、ここにウイルスが侵入する余地がある`}</strong>{`。この期間を突く攻撃のことを特に`}<strong parentName="p">{`ゼロデイ攻撃`}</strong>{`と呼んでいる。`}</p>
    <p>{`このゼロデイ攻撃に対応するためにはファイルを実行しないように気を付けなければならないが、その実行を防ぎ、駆除することがウイルス対策ソフトの役割となっている。`}</p>
    <p>{`ソフトウェアのアップデートはもちろん重要であるが、その弱点をウイルス対策ソフトで補う事を考慮に入れよう。`}</p>
    <h3 {...{
      "id": "標的型攻撃に対してはウイルス対策ソフトも無力な事が多い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%A8%99%E7%9A%84%E5%9E%8B%E6%94%BB%E6%92%83%E3%81%AB%E5%AF%BE%E3%81%97%E3%81%A6%E3%81%AF%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%82%BD%E3%83%95%E3%83%88%E3%82%82%E7%84%A1%E5%8A%9B%E3%81%AA%E4%BA%8B%E3%81%8C%E5%A4%9A%E3%81%84",
        "aria-label": "標的型攻撃に対してはウイルス対策ソフトも無力な事が多い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`標的型攻撃に対してはウイルス対策ソフトも無力な事が多い`}</h3>
    <p>{`不特定多数にウイルスメールを配布する全体型の攻撃に対して、特に企業の担当者などに狙いを定めてウイルスを送り付ける攻撃のことを標的型攻撃と呼んでいる。`}</p>
    <p>{`標的型攻撃にはウイルススキャンでは検出することが難しい未知のウイルスが使われるため防ぐことが難しい。大抵は関係者を装ってウイルス付きのメールが送られるためクリックされる可能性が高いことも難点である。`}</p>
    <p><strong parentName="p">{`ウイルス対策では防ぎ切れない攻撃は残念ながら存在するため、パスワードを安易にファイルに書いておかない、使いまわさないなどの自助努力による基本的なセキュリティも引き続き重要`}</strong>{`とはなる。`}</p>
    <h2 {...{
      "id": "Windows-Defenderのウイルス対策で十分か",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Windows-Defender%E3%81%AE%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%81%A7%E5%8D%81%E5%88%86%E3%81%8B",
        "aria-label": "Windows Defenderのウイルス対策で十分か permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Windows Defenderのウイルス対策で十分か?`}</h2>
    <Image {...props} name="windowsdefender" alt="Windows Defender" mdxType="Image" />
    <p>{`Windows DefenderはマイクロソフトがWindows10から標準的にプリインストールしているウイルス対策ソフトであり、下記のような特徴がある。`}</p>
    <List mdxType="List">
  <li>標準でインストールされ無償提供</li>
  <li>未知・既知ともにマルウェア検出率が高い</li>
  <li>法人シェアが高い</li>
    </List>
    <p>{`Windowsユーザから送られる膨大な情報を元にAIを用いて学習させているため既知・未知ともにウイルス検出能力は十分であり、セキュリティ会社のカスペルスキーが独占禁止法でマイクロソフトを訴えるなど業界としても脅威となっているソフトである。`}</p>
    <p>{`実際に法人のWindows10向けウイルス対策ソフトで全世界の50%を占めるようになっている。`}</p>
    <p>{`機能としてはファイル実行時のリアルタイムスキャンやランサムウェアからファイルを保護する機能、ブラウザ向けサイトスキャンなど基本的な機能を備えている。`}</p>
    <p><strong parentName="p">{`ブラウザ向けのスキャンはデフォルトではMicrosoft Edgeのみを対象としているが、Google Chrome向けに対しては拡張プラグインの形式で提供`}</strong>{`している。`}</p>
    <p>{`これらの事から`}<strong parentName="p">{`ウイルス対策としては十分に要件を満たしていると言える`}</strong>{`。ただし、Avastなどの無料ソフトを入れた方が良いのか？あるいはノートンやウイルスバスターと言った有料ソフトを導入した方が良いのかは検討する余地はある。`}</p>
    <h2 {...{
      "id": "無料ソフトは必要ない",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%84%A1%E6%96%99%E3%82%BD%E3%83%95%E3%83%88%E3%81%AF%E5%BF%85%E8%A6%81%E3%81%AA%E3%81%84",
        "aria-label": "無料ソフトは必要ない permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`無料ソフトは必要ない`}</h2>
    <p>{`Avastなど優秀なウイルス対策ソフトがあるものの、検出能力でそれほど差があるわけではなく、有料ソフトほどの機能性があるわけではないので中途半端な感がある。`}</p>
    <p>{`サードパーティー製のウイルス対策ソフトはユーザビリティに優れている事が多いため、使い勝手の良さで使う人もいるが、それほど使用する必要はないのではないかと思われる。また、無料ウイルス対策ソフトは有料版へのアップグレードの広告が時もあり、うっとうしく思うことも多い。`}</p>
    <p>{`なお、サードパーティー製のソフトウェアをインストールするとWindows Defenderのウイルス対策機能はオフとなり、競合はしないようになっている。`}</p>
    <p>{`一方でWindows7までのパソコンの場合は何もウイルス対策ソフトが入っていない場合は入れるべきである。`}</p>
    <h2 {...{
      "id": "有料ウイルス対策ソフトは使い勝手や機能に着目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%9C%89%E6%96%99%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%82%BD%E3%83%95%E3%83%88%E3%81%AF%E4%BD%BF%E3%81%84%E5%8B%9D%E6%89%8B%E3%82%84%E6%A9%9F%E8%83%BD%E3%81%AB%E7%9D%80%E7%9B%AE",
        "aria-label": "有料ウイルス対策ソフトは使い勝手や機能に着目 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`有料ウイルス対策ソフトは使い勝手や機能に着目`}</h2>
    <p>{`次に、有料ソフトはどうか？ウイルス検知能力としてはこちらもそれほど差があるわけではない。しかしパソコン単一ではなく、総合情報セキュリティとしての機能が有料ウイルス対策ソフトウェアには備わっている。それらに関する機能を説明する。`}</p>
    <h3 {...{
      "id": "パスワードマネージャー",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%91%E3%82%B9%E3%83%AF%E3%83%BC%E3%83%89%E3%83%9E%E3%83%8D%E3%83%BC%E3%82%B8%E3%83%A3%E3%83%BC",
        "aria-label": "パスワードマネージャー permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`パスワードマネージャー`}</h3>
    <p>{`パスワード流出は様々な場所で起こる。仮に自分がパソコン上のパスワードをきっちりと防衛できていたとしても、SNSやショッピングサイトの情報が漏洩することもある。`}</p>
    <p>{`また、パスワードが単純な文字列の羅列で強度が低い場合には攻撃により漏れることがあるし、複数サービスで同じアカウントを使用していた場合には芋づる式に漏れる可能性も高い。パスワードを狙った攻撃には例えば次のようなものがある。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>攻撃名</th>
      <th>概要</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>フィッシング詐欺</td>
      <td>偽の銀行サイトなどを表示して情報を盗む</td>
    </tr>
    <tr>
      <td>ワンクリック詐欺</td>
      <td>ウイルスに感染しましたなどとWebサイトにいきなり表示して、動揺を誘い個人情報を入力させる</td>
    </tr>
    <tr>
      <td>盗聴</td>
      <td>暗号化されていないネットワークを流れるデータから情報を盗む</td>
    </tr>
    <tr>
      <td>キーロガー</td>
      <td>バックグラウンドでソフトウェアが使用者のキー入力を読み取りサーバーへ送信</td>
    </tr>
    <tr>
      <td>ソーシャルエンジニアリング</td>
      <td>管理者などを装って電話でパスワードを聞き出す、横から入力を脇見するなど情報技術を利用せずに重要な情報を盗み出す。これはウイルス対策ソフトでは当然防げない。</td>
    </tr>
    <tr>
      <td>総当たり/辞書攻撃</td>
      <td>プログラムを使って文字を少しずつ変更し、可能な限りログインを試す。パスワードで良くある文字列の組み合わせを試すものを特に辞書攻撃という。</td>
    </tr>
    <tr>
      <td>パスワードリスト攻撃</td>
      <td>漏洩したパスワードを用いて、他のサイトでもログインできないかを試行</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`このように攻撃の種類は多く、漏洩の可能性は注意をしていてもそれなりにある。サービス側の過失で漏洩するというどうしようもないケースもあるため、`}<strong parentName="p">{`パスワードは漏れるもの`}</strong>{`と考えておいた方が良い。`}</p>
    <p>{`このためインターネットバンキングやGoolgeやアップルなどIT大手のサイトは、ID/Passwordを入力した後に追加でSMSコードやワンタイムパスワードを入力させる２段階認証を機能として備えている。`}</p>
    <p>{`ただ、２段階認証は利便性を損なうものでかつ、対応していないサービスも多いため、多くはパスワードのみの管理となっている。`}</p>
    <p>{`話が逸れたが、`}<strong parentName="p">{`パスワードは漏れるものと考え、漏れた場合の被害を最小限にするためにパスワードを使いまわさないことが重要`}</strong>{`である。`}</p>
    <p>{`とは言うものの何十もあるWebサービスのID/Passwordを全て覚えるのは現実的ではない。そこで`}<strong parentName="p">{`複数のパスワードを一元管理するソフトウェアであるパスワードマネージャー`}</strong>{`が活躍する。`}</p>
    <p>{`パスワードマネージャーは、全てのWebサービスのID/パスワードを管理し、ログイン時に自動で入力してくれるソフトである。このためパスワードマネージャーのパスワード以外を覚える必要が無く、`}<strong parentName="p">{`各サービスに別々のパスワードを入力することが容易になる`}</strong>{`メリットがある。`}</p>
    <p>{`クラウドで連携されており、PCで入力したパスワードをスマートフォンで自動入力することもできるため利便性も向上する。`}</p>
    <h3 {...{
      "id": "VPNVirtual-Private-Network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#VPNVirtual-Private-Network",
        "aria-label": "VPNVirtual Private Network permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`VPN(Virtual Private Network)`}</h3>
    <p>{`インターネット上を流れるデータはルータと言われる中継器を複数経由して目的地に辿り着く。その経路を流れるデータ(パケットデータ)を解読されて情報漏洩に繋がる可能性がある。この`}<strong parentName="p">{`ネットワーク上でのデータ解読行為を盗聴`}</strong>{`と呼ぶ。`}</p>
    <p>{`公衆無線LANを使う時、旅行中にホテルからインターネットにアクセスする時などは特に盗聴のリスクが高くなるが、VPNという仮想専用回線を使用することによりリスクを抑えることができる。`}</p>
    <p><strong parentName="p">{`旅先で気軽にWebサイトにログインすることができる`}</strong>{`のは大きなメリットである。製品としてはノートンセキュアVPNやMcAfee Safe ConnectなどのVPNがあり、プランにもよるが、ウイルス対策ソフトを購入すれば使えるようになる。`}</p>
    <h3 {...{
      "id": "チャットサポート電話サポート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%81%E3%83%A3%E3%83%83%E3%83%88%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E9%9B%BB%E8%A9%B1%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88",
        "aria-label": "チャットサポート電話サポート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`チャットサポート/電話サポート`}</h3>
    <p>{`チャットや電話でのサポートもいざウイルスに感染したような場合は頼りになる手段である。電話対応に関しては24時間対応というわけには行かないが、チャットは大抵の場合24時間対応となっている。`}</p>
    <h3 {...{
      "id": "ペアレンタルコントロール",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%9A%E3%82%A2%E3%83%AC%E3%83%B3%E3%82%BF%E3%83%AB%E3%82%B3%E3%83%B3%E3%83%88%E3%83%AD%E3%83%BC%E3%83%AB",
        "aria-label": "ペアレンタルコントロール permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ペアレンタルコントロール`}</h3>
    <p>{`子供に不適切なサイトを見せたくない場合に重宝する機能が`}<strong parentName="p">{`ペアレンタルコントロール`}</strong>{`である。これはアダルトサイトなどの子供にとって有害であるサイトを遮断することができる機能である。`}</p>
    <p>{`アダルトサイトや、怪しげなゲームサイトなどはウイルスの温床になっており、下手に閲覧してファイルをダウンロード何かしてしまうと高い確率でウイルスに感染する。特に子供は何をしでかすか分からないので対処しておいた方がよいだろう。`}</p>
    <h2 {...{
      "id": "有料ウイルス対策ソフトは必要か？",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%9C%89%E6%96%99%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E5%AF%BE%E7%AD%96%E3%82%BD%E3%83%95%E3%83%88%E3%81%AF%E5%BF%85%E8%A6%81%E3%81%8B%EF%BC%9F",
        "aria-label": "有料ウイルス対策ソフトは必要か？ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`有料ウイルス対策ソフトは必要か？`}</h2>
    <p>{`専門ベンダーが開発するウイルス対策ソフトに比べると検知率は劣るものの、Windows Defenderウイルス対策によりほぼ同等のウイルス対策ができるようになっている。`}</p>
    <p>{`あとは追加のパスワードマネージャーやVPN、サポート等の機能や、スマートフォン(Android/iOS)のセキュリティ防衛を合わせて考える事が重要である。`}</p>
    <p>{`費用対効果の観点から考えると、せいぜい数個(楽天/Amazon/ネットバンク一つ/Gmail/Microsoft Account)ぐらいしかアカウントを持っていない場合は覚えられるレベルでかつ、サービスが少ない分漏洩リスクも低いためパスワードマネージャーはコストを掛けて導入する必然性は薄いだろう。`}</p>
    <p>{`また、重要な情報は全て２段階認証で守っており、その他は漏れたとしても大した問題ではないと考えている人も費用対効果の観点で薄いかも知れない。`}</p>
    <p><strong parentName="p">{`日常的にパソコンを利用し、ネットバンキングやオンラインショッピング、その他のサービスも使うがセキュリティが心配という人は入れておくと利便性も高まり、安心もできる`}</strong>{`だろう。`}</p>
    <p>{`最後は年間5000円ほどの費用を高いと思うか安いと思うかというところ。このコスト感覚には人により違いがあるのでコスト以上のメリットがあると感じたら購入に踏み切ればよいだろう。`}</p>
    <h2 {...{
      "id": "おすすめのセキュリティソフト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%81%8A%E3%81%99%E3%81%99%E3%82%81%E3%81%AE%E3%82%BB%E3%82%AD%E3%83%A5%E3%83%AA%E3%83%86%E3%82%A3%E3%82%BD%E3%83%95%E3%83%88",
        "aria-label": "おすすめのセキュリティソフト permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`おすすめのセキュリティソフト`}</h2>
    <p>{`いかがだったであろうか？あとは自分のリスクと予算を天秤にかけてウイルス対策ソフトの検討を進めていって欲しい。最後に信頼できるウイルス対策ソフトを紹介する。インテルに買収後再独立をしたMcAfee、ロシア系のカスペルスキー、欧州系でキャノンが販売するESETなど様々なウイルス対策ソフトがあるが、その中でもノートンとウイルスバスターは個人的に使用経験があり、おすすめなので紹介しておく。`}</p>
    <h3 {...{
      "id": "ノートン360",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%83%8E%E3%83%BC%E3%83%88%E3%83%B3360",
        "aria-label": "ノートン360 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ノートン360`}</h3>
    <p>{`世界最大のセキュリティ会社であるアメリカのシマンテック社が販売する製品。`}<a parentName="p" {...{
        "href": "https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=148848.10000226&type=3&subid=0",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`シマンテックストア`}</a><img parentName="p" {...{
        "src": "https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=148848.10000226&type=3&subid=0",
        "alt": "''"
      }}></img>{`や店舗にて購入可能。`}</p>
    <p>{`ウイルス検知能力もWindows Defenderと比較して高く、VPNやパスワードマネージャーも使用できるため死角がない。オールマイティに安心して使えるセキュリティソフトである。`}</p>
    <p><strong parentName="p">{`セキュリティ会社として世界最大でかつ、情報セキュリティの専門家を数千人単位で抱え、歴史も長い安心と信頼のブランド`}</strong>{`である。`}</p>
    <h3 {...{
      "id": "ウイルスバスタークラウド",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%A6%E3%82%A4%E3%83%AB%E3%82%B9%E3%83%90%E3%82%B9%E3%82%BF%E3%83%BC%E3%82%AF%E3%83%A9%E3%82%A6%E3%83%89",
        "aria-label": "ウイルスバスタークラウド permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ウイルスバスタークラウド`}</h3>
    <p>{`ウイルス対策ソフト=ウイルスバスターだと思われているぐらい日本での知名度とシェアは高い。`}<a parentName="p" {...{
        "href": "https://click.linksynergy.com/fs-bin/click?id=xsv0FDnJs1M&offerid=517737.10000611&type=3&subid=0",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`ウイルスバスタークラウド`}</a><img parentName="p" {...{
        "src": "https://ad.linksynergy.com/fs-bin/show?id=xsv0FDnJs1M&bids=517737.10000611&type=3&subid=0",
        "alt": "''"
      }}></img>{`はトレンドマイクロという日本のセキュリティ会社の商品。`}</p>
    <p>{`日本での高いシェアを活かしたきめ細かいサポートが特徴。また重要なこととして、ウイルスは各国で流行っているものが違うため、`}<strong parentName="p">{`日本での高いシェアはそれだけ素早い対策に繋がる`}</strong>{`。`}</p>
    <p>{`ウイルス駆除率No.1と宣伝されていても、それが世界中のウイルスを対象としたもので、日本でのウイルスの傾向と異なっていたら無意味である。`}</p>
    <p>{`その他Web上での情報も充実しているため、特に初心者の人にとってはベストな選択になるのではと思われる。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      